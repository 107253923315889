<template>
  <div>
    <b-button
      size="sm"
      class="button-export-style mr-1 ml-1"
      v-b-modal.extract-modal
      title="Télecharger  les factures"
    >
      <font-awesome-icon icon="cloud-download-alt"
    /></b-button>
    <b-modal
      ref="extract-modal"
      id="extract-modal"
      :hide-footer="true"
      :hide-header="true"
      @hidden="resetModal()"
      modal-class="cutsom-modal-bootstrap modal-dialog-export"
    >
      <div class="hader mb-2">
        <div class="titleSetting">Générer un {{ typeModal }}</div>
        <div class="iconClose" @click.prevent="hideModal('extract-modal')">
          <font-awesome-icon icon="times" />
        </div>
      </div>
      <Card>
        <template v-slot:body>
          <form
            @submit.stop.prevent="handleExportFile"
            class="form-modal-custom-style"
          >
            <b-form-group
              id="fieldset-horizontal-type"
              label="Type de fichier : "
              label-for="type-modal"
              class="text-dark"
            >
              <b-form-select
                id="fieldset-horizontal-type"
                v-model="typeModal"
                :options="ListType"
                required
                @change="handleZip"
                class="b-form-select-raduis"
              ></b-form-select>
            </b-form-group>
            <b-form-group
              v-if="typeModal == 'xls'"
              id="fieldset-horizontal-type"
              label="Modèle : "
              label-for="type-modal"
              class="text-dark"
            >
              <b-form-select
                id="fieldset-horizontal-type"
                v-model="modelSelected"
                :options="computedModel"
                @change="handleUpdateModel"
                required
                class="b-form-select-raduis"
              ></b-form-select>
            </b-form-group>
            <div class="row mt-3" v-if="modelToUpdate && typeModal == 'xls'">
              <div class="col-1"></div>
              <div class="col-1">
                <b-form-checkbox
                  id="checkbox-1"
                  name="checkbox-1"
                  class="check-th-details ml-2"
                  v-model="checkAll"
                  @change="checkAllColumnUpdate"
                >
                </b-form-checkbox>
              </div>
              <div class="col-4 text-justify">
                <div id="info-wrap">
                  <div class="description-column">
                    <b class="text-dark text-deco"> Tous</b>
                  </div>
                </div>
              </div>
              <hr />
            </div>
            <div v-if="modelToUpdate && typeModal == 'xls'">
              <draggable :list="modelToUpdate.columns">
                <div
                  class="row"
                  v-for="(column, index) in modelToUpdate.columns"
                  :key="index"
                >
                  <div class="col-1">
                    <img src="@/assets/sort.png" class="sort-icon ml-2" />
                  </div>
                  <div class="col-1">
                    <b-form-checkbox
                      :id="'checkbox-1' + index"
                      name="checkbox-1"
                      class="check-th-details ml-2"
                      v-model="column.checked"
                      @change="computedModelUpdateCheck"
                    >
                    </b-form-checkbox>
                  </div>
                  <div class="col-4 text-justify">
                    <div id="info-wrap">
                      <div class="description-column">
                        <b class="text-dark text-deco">
                          {{ column.description }}</b
                        >
                      </div>
                      <div class="info-column">
                        <b-icon
                          icon="exclamation-circle-fill"
                          variant="success"
                          v-if="
                            column.column == '$num_dossier' ||
                              column.column == '$client_dossier' ||
                              column.column == '$montant_final' ||
                              column.column == '$bureau_etude' ||
                              column.column == '$installeur' ||
                              column.column == '$date_paiement_dossier' ||
                              column.column == '$date_depot'
                          "
                          title="Cette colonne pour les factures support / Master"
                        ></b-icon>
                        <b-icon
                          icon="exclamation-circle-fill"
                          variant="primary"
                          v-if="
                            column.column == '$qte' ||
                              column.column == '$pu_ht' ||
                              column.column == '$nom_produit' ||
                              column.column == '$description_produit' ||
                              column.column == '$ref_produit'
                          "
                          title="Cette colonne peut faire la duplication des factures"
                        ></b-icon>
                        <b-icon
                          icon="exclamation-circle-fill"
                          variant="warning"
                          v-if="column.column == '$comptabilite'"
                          title="Cette colonne pour l'interface comptabilité"
                        ></b-icon>
                        <b-icon
                          icon="exclamation-circle-fill"
                          variant="secondary"
                          v-if="column.column == '$sous_objet'"
                          title="Cette colonne pour les factures Sci / Mensuel"
                        ></b-icon>
                        <b-icon
                          icon="exclamation-circle-fill"
                          class="icon-export-oblige"
                          v-if="
                            column.column == '$organisme' ||
                              column.column == '$Kwh' ||
                              column.column == '$num_fac_avoir' ||
                              column.column == '$kwh_avoir' ||
                              column.column == '$ttc_avoir' ||
                              column.column == '$num_depot' ||
                              column.column == '$nom_depot' ||
                              column.column == '$ratio' ||
                              column.column == '$type' ||
                              column.code == '$master_filiale'
                          "
                          title="Cette colonne pour les factures obligées"
                        ></b-icon>
                      </div>
                    </div>
                  </div>
                  <div class="col-5">
                    <b-form-group class="input-modal-champ mb-0">
                      <b-form-input
                        :disabled="
                          column.column == '$num_facture' &&
                            oldModalToUpdate.is_system
                        "
                        required
                        class="input-modal-champ mb-0"
                        v-model="column.column_value"
                        autocomplete="off"
                      >
                      </b-form-input>
                    </b-form-group>
                  </div>
                  <hr />
                </div>
              </draggable>
            </div>
            <div class="form-modal-custom-style mt-2">
              <div class="messageError">
                <div v-if="error" class="error">
                  <ul v-if="Array.isArray(error)">
                    <li v-for="(e, index) in error" :key="index">
                      {{ e }}
                    </li>
                  </ul>
                  <span v-else>{{ error }}</span>
                </div>
              </div>
              <div class="actionModel">
                <b-button class="button-valide-style" type="submit">
                  <span>
                    Générer
                    <div v-if="loading" class="loading ml-2">
                      <div class="spinner-border" role="status"></div>
                    </div>
                  </span>
                </b-button>
              </div>
            </div>
          </form>
        </template>
      </Card>
    </b-modal>
  </div>
</template>

<script>
import moment from 'moment';
import draggable from 'vuedraggable';
import { mapActions, mapGetters } from 'vuex';
export default {
  props: {
    filter: { required: true },
    selected: { required: true },
    exportXls: { required: true },
    exportZip: { required: false },
    famille: { required: true },
    zipRequired: { required: false },
    xlsRequired: { required: false }
  },
  data() {
    return {
      checkAll: false,
      typeModal: null,
      modelSelected: null,
      loading: false,
      error: [],
      modelToUpdate: null,
      oldModelToUpdate: null,
      year: null
    };
  },
  methods: {
    ...mapActions(['getAllModelExports', 'updateModelExport']),
    computedModelUpdateCheck(value) {
      if (value == false && this.checkAll) {
        this.checkAll = false;
      } else {
        let selected = [];
        this.modelToUpdate.columns.forEach(item => {
          if (item.checked == true) {
            selected.push(item);
          }
        });
        if (this.modelToUpdate.columns.length == selected.length)
          [(this.checkAll = true)];
      }
    },
    checkAllColumnUpdate() {
      if (this.checkAll == true) {
        this.modelToUpdate.columns.forEach(item => {
          item.checked = true;
        });
      } else {
        this.modelToUpdate.columns.forEach(item => {
          this.oldModalToUpdate.columns.forEach(column => {
            if (column.id == item.id) {
              item.checked = column.checked;
            }
          });
        });
      }
    },
    handleZip() {
      this.error = null;
      if (this.typeModal == 'zip') {
        this.modelToUpdate = null;
        this.oldModelToUpdate = null;
      } else {
        this.models?.map(item => {
          if (item.id == this.modelSelected) {
            if (
              item &&
              item.user_name == this.getUserData.name &&
              this.getUserData.role == 'admin'
            ) {
              this.modelToUpdate = { ...item };
              this.oldModelToUpdate = item;
            } else {
              this.modelToUpdate = null;
              this.oldModelToUpdate = null;
            }
          }
        });
      }
    },
    async handleExportFile() {
      let mois = [
        'janvier',
        'février',
        'mars',
        'avril',
        'mai',
        'juin',
        'juillet',
        'aout',
        'septembre',
        'octobre',
        'novembre',
        'décembre'
      ];
      this.error = [];
      this.loading = true;
      let selectColumns = [];
      if (this.modelToUpdate != null) {
        this.modelToUpdate?.columns?.forEach(element => {
          if (element.checked == true) {
            selectColumns.push(element);
          }
        });
        if (selectColumns.length == 0) {
          this.error = 'Séléctionner au moins un champ';
          this.loading = false;
        } else {
          let bodyForm = new FormData();
          bodyForm.append('id', this.modelToUpdate.id);
          bodyForm.append('name', this.modelToUpdate.name);
          if (this.modelToUpdate.statut == true) {
            this.modelToUpdate.statut = 1;
          } else {
            this.modelToUpdate.statut = 0;
          }
          bodyForm.append('statut', this.modelToUpdate.statut);
          for (let i = 0; i < this.modelToUpdate.columns.length; i++) {
            bodyForm.append(
              'columns[' + i + '][id]',
              this.modelToUpdate.columns[i].id
            );

            bodyForm.append(
              'columns[' + i + '][column]',
              this.modelToUpdate.columns[i].column
            );
            bodyForm.append(
              'columns[' + i + '][description]',
              this.modelToUpdate.columns[i].description
            );
            bodyForm.append(
              'columns[' + i + '][column_value]',
              this.modelToUpdate.columns[i].column_value
            );
            bodyForm.append('columns[' + i + '][index]', i);
            if (
              this.modelToUpdate.columns[i] &&
              this.modelToUpdate.columns[i].checked
            ) {
              bodyForm.append('columns[' + i + '][blocked]', 0);
            } else {
              bodyForm.append('columns[' + i + '][blocked]', 1);
            }
          }
          let payload = {
            oldModelToUpdate: this.oldModelToUpdate,
            newModel: bodyForm
          };
          const response = await this.updateModelExport(payload);
          if (response) {
            let bodyFormData = new FormData();
            Object.keys(this.filter).forEach(key => {
              if (key == 'col') {
                bodyFormData.append('column', this.filter[key]);
              }
            });
            if (this.famille != null) {
              bodyFormData.append('famille', this.famille);
            }
            bodyFormData.append('order', 'DESC');
            bodyFormData.append('modelExport_id', this.modelSelected);
            if (this.selected.length != 0) {
              for (let i = 0; i < this.selected.length; i++) {
                bodyFormData.append('ids[' + i + ']', this.selected[i]);
              }
            } else {
              Object.keys(this.filter).forEach(key => {
                if (key == 'date_fin' && this.filter[key] != null) {
                  bodyFormData.append('date_fin', this.filter[key]);
                } else if (key == 'comptabilite' && this.filter[key] != null) {
                  bodyFormData.append('comptabilite', this.filter[key]);
                } else if (key == 'year' && this.filter[key] != null) {
                  bodyFormData.append('year', this.filter[key]);
                  this.year = this.filter[key];
                } else if (key == 'month') {
                  if (this.filter[key] != null) {
                    bodyFormData.append('month', this.filter[key]);
                    let index = mois.indexOf(this.filter[key]);
                    let date_debut = moment(
                      this.year + '-' + (index + 1) + '-01'
                    )
                      .startOf('month')
                      .format('YYYY-MM-DD');
                    let date_fin = moment(this.year + '-' + (index + 1) + '-01')
                      .endOf('month')
                      .format('YYYY-MM-DD');
                    bodyFormData.append('date_fin', date_fin);
                    bodyFormData.append('date_debut', date_debut);
                  } else {
                    let date_debut = moment('' + this.year + '')
                      .startOf('year')
                      .format('YYYY-MM-DD');
                    let date_fin = moment('' + this.year + '')
                      .endOf('year')
                      .format('YYYY-MM-DD');
                    bodyFormData.append('date_fin', date_fin);
                    bodyFormData.append('date_debut', date_debut);
                  }
                } else if (key == 'date_debut' && this.filter[key] != null) {
                  bodyFormData.append('date_debut', this.filter[key]);
                } else if (key == 'filterFamilly' && this.filter[key] != null) {
                  bodyFormData.append('support_familly', this.filter[key].id);
                } else if (key == 'search' && this.filter[key] != null) {
                  bodyFormData.append('search', this.filter[key]);
                } else if (key == 'num' && this.filter[key] != null) {
                  bodyFormData.append('num', this.filter[key]);
                } else if (key == 'objet' && this.filter[key] != null) {
                  bodyFormData.append('objet', this.filter[key]);
                } else if (Array.isArray(this.filter[key])) {
                  if (key == 'filterMasterFiliale') {
                    for (let i = 0; i < this.filter[key].length; i++) {
                      bodyFormData.append(
                        'masters[' + i + ']',
                        this.filter[key][i].id
                      );
                    }
                  } else if (key == 'filterSupport') {
                    for (let i = 0; i < this.filter[key].length; i++) {
                      bodyFormData.append(
                        'supports[' + i + ']',
                        this.filter[key][i].id
                      );
                    }
                  } else if (key == 'filterFiliale') {
                    for (let i = 0; i < this.filter[key].length; i++) {
                      bodyFormData.append(
                        'clients[' + i + ']',
                        this.filter[key][i].id
                      );
                    }
                  } else if (key == 'filterStatut') {
                    for (let i = 0; i < this.filter[key].length; i++) {
                      bodyFormData.append(
                        'statut[' + i + ']',
                        this.filter[key][i].value
                      );
                    }
                  } else if (key == 'filterStatutInstallateur') {
                    for (let i = 0; i < this.filter[key].length; i++) {
                      bodyFormData.append(
                        'statue_installateur[' + i + ']',
                        this.filter[key][i].value
                      );
                    }
                  } else if (key == 'filiale') {
                    for (let i = 0; i < this.filter[key].length; i++) {
                      bodyFormData.append(
                        'filiale[' + i + ']',
                        this.filter[key][i].id
                      );
                    }
                  } else if (key == 'depot') {
                    for (let i = 0; i < this.filter[key].length; i++) {
                      bodyFormData.append(
                        'depot[' + i + ']',
                        this.filter[key][i]
                      );
                    }
                  } else if (key == 'statue') {
                    for (let i = 0; i < this.filter[key].length; i++) {
                      bodyFormData.append(
                        'statue[' + i + ']',
                        this.filter[key][i].value
                      );
                    }
                  } else if (key == 'master_filiale') {
                    for (let i = 0; i < this.filter[key].length; i++) {
                      bodyFormData.append(
                        'master_filiale[' + i + ']',
                        this.filter[key][i].id
                      );
                    }
                  } else if (key == 'type') {
                    for (let i = 0; i < this.filter[key].length; i++) {
                      bodyFormData.append(
                        'type[' + i + ']',
                        this.filter[key][i].value
                      );
                    }
                  } else if (key == 'etat') {
                    for (let i = 0; i < this.filter[key].length; i++) {
                      bodyFormData.append(
                        'etat[' + i + ']',
                        this.filter[key][i].value
                      );
                    }
                  } else if (key == 'statut_client') {
                    for (let i = 0; i < this.filter[key].length; i++) {
                      bodyFormData.append(
                        'statut_client[' + i + ']',
                        this.filter[key][i].value
                      );
                    }
                  } else if (key == 'vendeur') {
                    for (let i = 0; i < this.filter[key].length; i++) {
                      bodyFormData.append(
                        'vendeur_id[' + i + ']',
                        this.filter[key][i].value
                      );
                    }
                  } else if (key == 'categorie') {
                    for (let i = 0; i < this.filter[key].length; i++) {
                      bodyFormData.append(
                        'categorie_id[' + i + ']',
                        this.filter[key][i].value
                      );
                    }
                  } else if (key == 'acheteur') {
                    for (let i = 0; i < this.filter[key].length; i++) {
                      bodyFormData.append(
                        'acheteur_id[' + i + ']',
                        this.filter[key][i].value
                      );
                    }
                  } else if (key == 'mode_reglement') {
                    for (let i = 0; i < this.filter[key].length; i++) {
                      bodyFormData.append(
                        'mode_reglement[' + i + ']',
                        this.filter[key][i].value
                      );
                    }
                  } else if (key == 'vendeur_id') {
                    for (let i = 0; i < this.filter[key].length; i++) {
                      bodyFormData.append(
                        'vendeur_id[' + i + ']',
                        this.filter[key][i].id
                      );
                    }
                  } else if (key == 'acheteur_id') {
                    for (let i = 0; i < this.filter[key].length; i++) {
                      bodyFormData.append(
                        'acheteur_id[' + i + ']',
                        this.filter[key][i].id
                      );
                    }
                  } else if (key == 'family') {
                    for (let i = 0; i < this.filter[key].length; i++) {
                      bodyFormData.append(
                        'family[' + i + ']',
                        this.filter[key][i].value
                      );
                    }
                  }
                } else {
                  if (this.filter[key] != null) {
                    bodyFormData.append(key, this.filter[key]);
                  }
                }
              });
            }
            const response = await this.exportXls(bodyFormData);
            if (response.succes) {
              this.loading = false;
              this.hideModal('extract-modal');
              this.$emit('end', false);
              this.$alert('', response.msg, 'success');
            } else {
              this.loading = false;
              this.error = 'Une erreur est survenue';
            }
          }
        }
      } else {
        if (this.typeModal == 'xls') {
          let bodyFormData = new FormData();
          Object.keys(this.filter).forEach(key => {
            if (key == 'col') {
              bodyFormData.append('column', this.filter[key]);
            }
          });
          bodyFormData.append('famille', this.famille);
          bodyFormData.append('order', 'DESC');
          bodyFormData.append('modelExport_id', this.modelSelected);
          if (this.selected.length != 0) {
            for (let i = 0; i < this.selected.length; i++) {
              bodyFormData.append('ids[' + i + ']', this.selected[i]);
            }
          } else {
            Object.keys(this.filter).forEach(key => {
              if (key == 'date_fin' && this.filter[key] != null) {
                bodyFormData.append('date_fin', this.filter[key]);
              } else if (key == 'comptabilite' && this.filter[key] != null) {
                bodyFormData.append('comptabilite', this.filter[key]);
              } else if (key == 'year' && this.filter[key] != null) {
                bodyFormData.append('year', this.filter[key]);
              } else if (key == 'month') {
                if (this.filter[key] != null) {
                  bodyFormData.append('month', this.filter[key]);
                  let index = mois.indexOf(this.filter[key]);
                  let date_debut = moment(this.year + '-' + (index + 1) + '-01')
                    .startOf('month')
                    .format('YYYY-MM-DD');
                  let date_fin = moment(this.year + '-' + (index + 1) + '-01')
                    .endOf('month')
                    .format('YYYY-MM-DD');
                  bodyFormData.append('date_fin', date_fin);
                  bodyFormData.append('date_debut', date_debut);
                } else {
                  let date_debut = moment('' + this.year + '')
                    .startOf('year')
                    .format('YYYY-MM-DD');
                  let date_fin = moment('' + this.year + '')
                    .endOf('year')
                    .format('YYYY-MM-DD');
                  bodyFormData.append('date_fin', date_fin);
                  bodyFormData.append('date_debut', date_debut);
                }
              } else if (key == 'date_debut' && this.filter[key] != null) {
                bodyFormData.append('date_debut', this.filter[key]);
              } else if (key == 'filterFamilly' && this.filter[key] != null) {
                bodyFormData.append('support_familly', this.filter[key].id);
              } else if (key == 'num' && this.filter[key] != null) {
                bodyFormData.append('num', this.filter[key]);
              } else if (key == 'search' && this.filter[key] != null) {
                bodyFormData.append('search', this.filter[key]);
              } else if (key == 'objet' && this.filter[key] != null) {
                bodyFormData.append('objet', this.filter[key]);
              } else if (Array.isArray(this.filter[key])) {
                if (key == 'filterMasterFiliale') {
                  for (let i = 0; i < this.filter[key].length; i++) {
                    bodyFormData.append(
                      'masters[' + i + ']',
                      this.filter[key][i].id
                    );
                  }
                } else if (key == 'filterSupport') {
                  for (let i = 0; i < this.filter[key].length; i++) {
                    bodyFormData.append(
                      'supports[' + i + ']',
                      this.filter[key][i].id
                    );
                  }
                } else if (key == 'filterFiliale') {
                  for (let i = 0; i < this.filter[key].length; i++) {
                    bodyFormData.append(
                      'clients[' + i + ']',
                      this.filter[key][i].id
                    );
                  }
                } else if (key == 'filterStatut') {
                  for (let i = 0; i < this.filter[key].length; i++) {
                    bodyFormData.append(
                      'statut[' + i + ']',
                      this.filter[key][i].value
                    );
                  }
                } else if (key == 'filterStatutInstallateur') {
                  for (let i = 0; i < this.filter[key].length; i++) {
                    bodyFormData.append(
                      'statue_installateur[' + i + ']',
                      this.filter[key][i].value
                    );
                  }
                } else if (key == 'filiale') {
                  for (let i = 0; i < this.filter[key].length; i++) {
                    bodyFormData.append(
                      'filiale[' + i + ']',
                      this.filter[key][i].id
                    );
                  }
                } else if (key == 'depot') {
                  for (let i = 0; i < this.filter[key].length; i++) {
                    bodyFormData.append(
                      'depot[' + i + ']',
                      this.filter[key][i]
                    );
                  }
                } else if (key == 'statue') {
                  for (let i = 0; i < this.filter[key].length; i++) {
                    bodyFormData.append(
                      'statue[' + i + ']',
                      this.filter[key][i].value
                    );
                  }
                } else if (key == 'master_filiale') {
                  for (let i = 0; i < this.filter[key].length; i++) {
                    bodyFormData.append(
                      'master_filiale[' + i + ']',
                      this.filter[key][i].id
                    );
                  }
                } else if (key == 'type') {
                  for (let i = 0; i < this.filter[key].length; i++) {
                    bodyFormData.append(
                      'type[' + i + ']',
                      this.filter[key][i].value
                    );
                  }
                } else if (key == 'etat') {
                  for (let i = 0; i < this.filter[key].length; i++) {
                    bodyFormData.append(
                      'etat[' + i + ']',
                      this.filter[key][i].value
                    );
                  }
                } else if (key == 'statut_client') {
                  for (let i = 0; i < this.filter[key].length; i++) {
                    bodyFormData.append(
                      'statut_client[' + i + ']',
                      this.filter[key][i].value
                    );
                  }
                } else if (key == 'vendeur') {
                  for (let i = 0; i < this.filter[key].length; i++) {
                    bodyFormData.append(
                      'vendeur_id[' + i + ']',
                      this.filter[key][i].value
                    );
                  }
                } else if (key == 'categorie') {
                  for (let i = 0; i < this.filter[key].length; i++) {
                    bodyFormData.append(
                      'categorie_id[' + i + ']',
                      this.filter[key][i].value
                    );
                  }
                } else if (key == 'acheteur') {
                  for (let i = 0; i < this.filter[key].length; i++) {
                    bodyFormData.append(
                      'acheteur_id[' + i + ']',
                      this.filter[key][i].value
                    );
                  }
                } else if (key == 'mode_reglement') {
                  for (let i = 0; i < this.filter[key].length; i++) {
                    bodyFormData.append(
                      'mode_reglement[' + i + ']',
                      this.filter[key][i].value
                    );
                  }
                } else if (key == 'vendeur_id') {
                  for (let i = 0; i < this.filter[key].length; i++) {
                    bodyFormData.append(
                      'vendeur_id[' + i + ']',
                      this.filter[key][i].id
                    );
                  }
                } else if (key == 'acheteur_id') {
                  for (let i = 0; i < this.filter[key].length; i++) {
                    bodyFormData.append(
                      'acheteur_id[' + i + ']',
                      this.filter[key][i].id
                    );
                  }
                } else if (key == 'family') {
                  for (let i = 0; i < this.filter[key].length; i++) {
                    bodyFormData.append(
                      'family[' + i + ']',
                      this.filter[key][i].value
                    );
                  }
                }
              } else {
                if (this.filter[key] != null) {
                  bodyFormData.append(key, this.filter[key]);
                }
              }
            });
          }
          const response = await this.exportXls(bodyFormData);
          if (response) {
            this.loading = false;
            this.hideModal('extract-modal');
            this.$emit('end', false);
          } else {
            this.loading = false;
            this.error = 'Une erreur est survenue';
          }
        } else {
          let bodyFormData = new FormData();
          if (this.selected.length != 0) {
            for (let i = 0; i < this.selected.length; i++) {
              bodyFormData.append('ids[' + i + ']', this.selected[i]);
            }
            const response = await this.exportZip(bodyFormData);
            if (response) {
              this.loading = false;
              this.hideModal('extract-modal');
              this.$emit('end', false);
            } else {
              this.loading = false;
              this.error = 'Une erreur est survenue';
            }
          } else {
            this.loading = false;
            this.error = 'Séléctionner au moins un dossier';
          }
        }
      }
    },
    hideModal(ref) {
      this.$refs[ref].hide();
      this.resetModal();
    },
    resetModal() {
      this.year = null;
      this.checkAll = false;
      this.typeModal = null;
      this.modelSelected = null;
      (this.loading = false), (this.error = []);
      this.modelSelected = this.computedModel[0]?.id;
      this.modelToUpdate = null;
      this.models?.map(item => {
        if (item.id == this.modelSelected) {
          if (
            item &&
            item.user_name == this.getUserData.name &&
            this.getUserData.role == 'admin'
          ) {
            this.modelToUpdate = { ...item };
            this.oldModalToUpdate = JSON.parse(JSON.stringify(item));
            this.oldModelToUpdate = item;
            let selected = [];
            this.modelToUpdate.columns.forEach(item => {
              if (item.checked == true) {
                selected.push(item);
              }
            });
            if (this.modelToUpdate.columns.length == selected.length)
              [(this.checkAll = true)];
          } else {
            this.modelToUpdate = null;
            this.oldModelToUpdate = null;
            this.oldModalToUpdate = null;
          }
        }
      });
    },
    handleUpdateModel() {
      this.checkAll = false;
      this.models?.map(item => {
        if (item.id == this.modelSelected) {
          if (
            item.user_name == this.getUserData.name ||
            this.getUserData.role == 'admin'
          ) {
            this.modelToUpdate = { ...item };
            this.oldModalToUpdate = JSON.parse(JSON.stringify(item));
            this.oldModelToUpdate = item;
            let selected = [];
            this.modelToUpdate.columns.forEach(item => {
              if (item.checked == true) {
                selected.push(item);
              }
            });
            if (this.modelToUpdate.columns.length == selected.length)
              [(this.checkAll = true)];
          } else {
            this.modelToUpdate = null;
            this.oldModelToUpdate = null;
            this.oldModalToUpdate = null;
          }
        }
      });
    }
  },
  computed: {
    ...mapGetters(['models', 'getUserData']),
    ListType() {
      let list = [];
      if (this.xlsRequired == true) {
        list.push({ value: 'xls', text: 'xls' });
      }
      if (this.zipRequired == true) {
        list.push({ value: 'zip', text: 'zip' });
      }
      return list;
    },
    computedModel() {
      if (this.models.length) {
        return this.models?.map(item => {
          if (item.user_name == 'admin') {
            return {
              name: item.name,
              id: item.id,
              columns: item.columns,
              value: item.id,
              text: item.name + ' ( Système )'
            };
          } else {
            return {
              name: item.name,
              id: item.id,
              columns: item.columns,
              value: item.id,
              text: item.name + ' (' + item.user_name + ')'
            };
          }
        });
      }
      return [];
    }
  },
  components: {
    Card: () => import('../../../../component/card.vue'),
    draggable
  },
  async mounted() {
    await this.getAllModelExports();
    if (this.computedModel.length) {
      this.modelSelected = this.computedModel[0]?.id;
    }
    this.models?.map(item => {
      if (item.id == this.modelSelected) {
        if (
          item &&
          item.user_name == this.getUserData.name &&
          this.getUserData.role == 'admin'
        ) {
          this.modelToUpdate = { ...item };
          this.oldModalToUpdate = JSON.parse(JSON.stringify(item));
          this.oldModelToUpdate = item;
          let selected = [];
          this.modelToUpdate.columns.forEach(item => {
            if (item.checked == true) {
              selected.push(item);
            }
          });
          if (this.modelToUpdate.columns.length == selected.length)
            [(this.checkAll = true)];
        } else {
          this.modelToUpdate = null;
          this.oldModelToUpdate = null;
          this.oldModalToUpdate = null;
        }
      }
    });
  }
};
</script>

<style scoped lang="scss">
.sort-icon {
  height: 20px;
  width: 20px;
}
</style>
<style lang="scss">
.align-item-extract-modal {
  display: flex;
  .form-group {
    width: 50%;
  }
}
.modal-extaction-bdd-frais {
  .d-block,
  #fieldset-horizontal,
  #fieldset-colonne-horizontal,
  #fieldset-headers-horizontal,
  #fieldset-horizontal-type {
    font-size: 12px;
    font-weight: 600;
    font-family: 'Montserrat', sans-serif;
    text-align: start;
    color: #2a2a2a;
    margin-left: 3%;
    margin-top: 8px;
    margin-bottom: 1px;
  }
  .form-modal-custom-style {
    text-align: center;
  }
}
#info-wrap {
  /* overflow to handle inner floating block */
  overflow: hidden;
}

.description-column {
  width: 90%;
  float: left;
}
.info-column {
  width: 10%;
  float: left;
}
</style>
